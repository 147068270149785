<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12">
        <v-row>
          <v-col md="6" class="gray-background">
            <div>
              <table class="width-100" style="margin-left: -21px">
                <tr>
                  <td colspan="2">
                    <h2 class="margin-auto color-custom-blue font-weight-600">
                      Overview
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize d-flex">
                    Remark:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ overview.remark || " - " }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-18 text-capitalize">
                    Closer Remark:
                  </td>
                  <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                    {{ overview.closer_remark || " - " }}
                  </td>
                </tr>
                <!-- <tr>
                    <td class="pb-2 font-size-18 text-capitalize">Relation:</td>
                    <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                      {{ engineer.emergency_contact_relation || " - " }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2 font-size-18 text-capitalize">Email:</td>
                    <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                      {{ engineer.emergency_contact_email || " - " }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2 font-size-18 text-capitalize">Address:</td>
                    <td class="pb-2 font-size-18 font-weight-600 text-capitalize">
                      {{ engineer.emergency_contact_unit_no }},
                      {{ engineer.emergency_contact_street_1 }},
                      {{ engineer.emergency_contact_street_2 }},
                      {{ engineer.emergency_contact_postal_code }}.
                    </td>
                  </tr> -->
              </table>
            </div>
          </v-col>
          <v-col md="6" class="gray-background">
            <div style="height: calc(100vh - 430px) !important; overflow: auto">
              <table class="width-100">
                <thead class="custom-header">
                  <tr>
                    <td colspan="4">
                      <h2 class="margin-auto color-custom-blue font-weight-600">
                        Images
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <th class="p-2 light-blue-bg" width="50"></th>
                    <th class="p-2 light-blue-bg" width="150">Name</th>
                    <th class="p-2 light-blue-bg" width="150"></th>
                    <th class="p-2 light-blue-bg" width="300">Action</th>
                  </tr>
                </thead>

                <tbody v-if="overview.attachments.length">
                  <tr v-for="(row, index) in overview.attachments" :key="index">
                    <td class="p-2 border-top-light-grey" width="50">
                      <template>
                        <ImageTemplate
                          :src="row.file.url"
                          style="max-width: 50px; width: 50px"
                        ></ImageTemplate>
                      </template>
                    </td>
                    <td class="p-2 border-top-light-grey" width="250">
                      <p
                        class="m-0 blue--text font-level-1 cursor-pointer bold-600"
                        v-on:click="doAction(row, 'download')"
                      >
                        {{ row.name }}
                      </p>
                    </td>
                    <td class="p-2 border-top-light-grey" width="150"></td>
                    <td class="p-2 border-top-light-grey" width="150">
                      <v-btn
                        v-on:click="downloadAttachment(row.file.url)"
                        icon
                        depressed
                        color="blue darken-4"
                        class="ml-n2"
                        ><v-icon medium>mdi-download</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <tfoot v-else>
                  <tr>
                    <td colspan="3">
                      <p class="m-0 row-not-found">
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no file at the moment.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <Images
              v-if="false"
              :images="overview.attachments"
              :parent="overview.id"
              type="incident"
              :isPageLoading="pageLoading"
            ></Images>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Images from "@/view/pages/partials/Detail/Image";
import ImageTemplate from "@/view/pages/Image";

export default {
  name: "emergency-contact",
  mixins: [CommonMixin],
  props: {
    overview: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      fieldDescriptions: [],
      pageLoading: false,
    };
  },
  components: {
    Images,
    ImageTemplate,
  },
};
</script>
